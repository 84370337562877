import * as React from "react"
import { graphql } from "gatsby"
import CanvasLayout from "../../components/layout/CanvasLayout"
import Container from "../../components/layout/Container"
import Type from "../../components/typography/Type"
import { isLoggedIn } from "../../services/auth"
import LoginPrompt from "../../components/app/loginPrompt"
import UserNeedCard from "../../components/cards/UserNeedCard"
import FeatureCard from "../../components/cards/FeatureCard"
import Toggle from "../../components/controls/Toggle"
import Layout from "../../components/layout/Layout"
import CapabilityCard from "../../components/cards/CapabilityCard"
import CanvasToolbar from "../../components/canvas/CanvasToolbar"
import Canvas from "../../components/canvas/Canvas"




import { useState } from "react"



export default function Pages( {data}) {


const [isOptions, toggleOptions] = useState(false);
const [isGrid, toggleGrid] = useState(true);



const columnWidth = 320;
const horPadding = 0;
const verPadding = 12;


return (
  <>
  <CanvasLayout>
  {isLoggedIn() ? (

<div>
<CanvasToolbar>
     <div><a href={`${data.airtable.data.ProjectPath} Capability Map`}><span className="">&lt; Back to {data.airtable.data.ProjectTxt}</span></a></div>
     
     <div className="grow">
       <div className="flex flex-row justify-end space-x-8 lg:items-center">
         <div><button onClick={() => toggleOptions(!isOptions)} className="">Options</button></div>
         <div className={`${ isOptions ? `block` : `hidden` } flex flex-col lg:flex-row lg:justify-end space-y-2 lg:space-y-0 space-x-0 lg:space-x-8`}>
         
         <Toggle action="Grid" object={isGrid} switch={toggleGrid} />
         
         </div>  
       </div>
     </div> 
     
   </CanvasToolbar>  
   
    <Canvas title={data.airtable.data.Name} introduction={data.airtable.data.Introduction}>  

 <div className={`${ isGrid ? `grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 grid  gap-12` : `flex flex-col lg:flex-row space-x-0 lg:space-x-12 space-y-12 lg:space-y-0`} p-20`}>


  {data.airtable.data.CapabilityGroups.map((CapabilityGroupsArray) => ( 
 
   <div className={`${ isGrid ? `` : `min-w-[320px]`}  `}>
   <CapabilityCard title={CapabilityGroupsArray.data.Name} level="level1" resourceIconURL={CapabilityGroupsArray.data.ResourceIconURL} url={CapabilityGroupsArray.data.ResourceURL} iconURL={CapabilityGroupsArray.data.IconURL} introduction={CapabilityGroupsArray.data.Introduction}>
     <div className="grid grid-cols-1 gap-8">
       {CapabilityGroupsArray.data.Capabilities.map((CapabilityArray) => ( 
         <div>
           <CapabilityCard title={CapabilityArray.data.Name} level="level2" resourceIconURL={CapabilityArray.data.ResourceIconURL} url={CapabilityArray.data.ResourceURL} introduction={CapabilityArray.data.Introduction}>
           <div className="grid grid-cols-1  gap-4 mt-4">
             {CapabilityArray.data.Products.map((ProductArray) => (
               <FeatureCard title={ProductArray.data.Name} url={ProductArray.data.Path} />
               ))}
           </div>
           </CapabilityCard>
         </div>
       ))}
   </div>
   </CapabilityCard>
   </div>
 ))}
 
 </div>
    </Canvas>
</div>

 
    
   ) : (
     <div>
       <LoginPrompt/>
     </div>     
   )}
  </CanvasLayout>
    </>

  )
}

export const Head = () => <title>Capabilities</title>


export const query = graphql`
  query GetPage($Path: String!) {
    airtable(table: { eq: "Projects" },
    data: { CapabilitiesPath: { eq: $Path } }) {
      data {
        Name
        ProjectTxt
        ProjectPath
        CapabilityGroups {data {
          Name
          Capabilities {data {
            Name
            Products { data {
              Name
              Path
            }
            }
          }}
        }}
      }
    }
  }
`
